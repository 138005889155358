<template>
  <v-app style="height: 100%">
    <v-container fluid>
      <v-row align="center" justify="center" class="mb-5">
        <div align="center" justify="center">
          <v-avatar size="150px" color="grey darken-3" v-if="!pic">
            <v-img
              src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
              alt="User"
          /></v-avatar>
          <v-avatar size="150px" color="grey darken-3" v-if="pic">
            <v-img :src="pic" alt="User"
          /></v-avatar>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h4">
                {{ name }}
              </v-list-item-title>
              <v-list-item-title class="text-h5">
                {{ email }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
        <div class="block_menu">
          <template>
            <v-list rounded color="#eeeeee">
              <div class="text-h4 mt-12 mb-5" style="margin-right: 200px">
                <span class="text-h5"
                  >Please choose a login role as below:</span
                >
              </div>
              <v-list-item-group color="primary">
                <v-list-item
                  class="rounded-pill"
                  style="background-color: #dbc9ff"
                  two-line
                  v-for="(i, index) in roles"
                  v-bind:key="index"
                >
                  <v-icon size="40">mdi-account-circle-outline</v-icon>

                  <v-list-item-content @click="LogInByRole(i.value)">
                    <v-list-item-title class="text-center">{{
                      i.name_en
                    }}</v-list-item-title>
                    <v-list-item-subtitle class="text-h4 text-center">{{
                      i.name_kh
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-icon size="40">mdi-chevron-right</v-icon>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </template>
        </div>
      </v-row>
    </v-container>
    <div>
      <div class="loading">
        <loading
          :active.sync="isLoading"
          :is-full-page="fullPage"
          :opacity="0.9"
          background-color="#dedede"
          :width="40"
          :height="40"
        >
          <div v-if="myLoading">
            <img
              width="100%"
              src="https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Floading.gif?alt=media&token=58553b10-7335-42a6-a0c8-8a6d55927816"
            />
          </div>
        </loading>
      </div>
    </div>
  </v-app>
</template>
<script>
import store from "@/store";
import { exchange } from "@schoolbase/web-client-lib";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  props: ["fullPage", "alertMessage", "color", "alertText"],
  data: () => ({
    pic: "",
    name: "",
    email: "",
    roles: [],
    token: store.getters.getToken,
    listPayload: [],
    roleExchange: [],
    myLoading: false,
    isLoading: false,
  }),
  components: {
    Loading,
  },
  methods: {
    loadLoggedUser() {
      let loggedUser = store.getters.getLoggedUser;
      this.pic = loggedUser.payload.user.photoURL;
      this.name = loggedUser.payload.user.name_en;
      this.email = loggedUser.payload.user.email;
      this.roles = loggedUser.payload.roles;
    },
    async LogInByRole(roleValue) {
      try {
        this.isLoading = true;
        this.myLoading = true;
        const APIResponse = await exchange(this.token, roleValue);
        const dataExchange = APIResponse;
        const tokenExchange = APIResponse.payload.token;
        const roleExchange = APIResponse.payload.roles[0];
        if (roleExchange) {
          if (roleExchange.value == "super_admin") {
            this.$store.commit("LOGGED_USER", dataExchange);
            this.$store.commit("LOGGED_TOKEN", tokenExchange);
            this.$router.replace({ name: "ManageUser" });
          } else if (roleExchange.value == "top_manager") {
            this.$store.commit("LOGGED_USER", dataExchange);
            this.$store.commit("LOGGED_TOKEN", tokenExchange);
            this.$router.replace({ name: "TopManagerDashboard" });
          } else if (roleExchange.value == "tsc") {
            this.$store.commit("LOGGED_TOKEN", tokenExchange);
            this.$store.commit("LOGGED_USER", dataExchange);
            this.$router.replace({ name: "TscDashboard" });
          } else if (roleExchange.value == "lecturer") {
            this.$store.commit("LOGGED_TOKEN", tokenExchange);
            this.$store.commit("LOGGED_USER", dataExchange);
            this.$router.replace({ name: "Session" });
          } else if (roleExchange.value == "student") {
            this.$store.commit("LOGGED_TOKEN", tokenExchange);
            this.$store.commit("LOGGED_USER", dataExchange);
            this.$router.replace({ name: "StudentDashboards" });
          } else if (roleExchange.value.includes("major_admin")) {
            this.$store.commit("LOGGED_TOKEN", tokenExchange);
            this.$store.commit("LOGGED_USER", dataExchange);
            this.$router.replace({ name: "ManageMajorGen" });
          } else {
            this.$router.replace({ name: "Anonymous" });
          }
        }
        this.isLoading = true;
        this.myLoading = true;
      } catch (e) {
        this.isLoading = true;
        this.myLoading = true;
        window.console.log(e);
      }
    },
  },
  mounted() {
    this.loadLoggedUser();
  },
};
</script>
<style scoped>
.block_menu .v-list-item__content > * {
  color: #000000;
  font-size: 20px !important;
  font-weight: 500 !important;
  line-height: 40px !important;
  text-transform: capitalize;
  font-family: "Poppins", serif !important;
}
.v-list .v-list-item__icon i {
  color: #54667a;
}
</style>
